$(function(){
    
    
    $.datepicker.regional['fr'] = {
                    closeText: 'Fermer',
                    prevText: 'Précédent',
                    nextText: 'Suivant',
                    currentText: 'Aujourd\'hui',
                    monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
                    monthNamesShort: ['Janv.','Févr.','Mars','Avril','Mai','Juin','Juil.','Août','Sept.','Oct.','Nov.','Déc.'],
                    dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
                    dayNamesShort: ['Dim.','Lun.','Mar.','Mer.','Jeu.','Ven.','Sam.'],
                    dayNamesMin: ['D','L','M','M','J','V','S'],
                    weekHeader: 'Sem.',
                    dateFormat: 'dd/mm/yy',
                    firstDay: 1,
                    isRTL: false,
                    showMonthAfterYear: false,
                    yearSuffix: ''};
                
    $.datepicker.setDefaults($.datepicker.regional['fr']);


    // permet d'appliquer un format identique à tous les champs utilisant datepicker
    // afin d'obtenir le calendrier, n'oubliez pas de modifier le code de votre form pour appliqer la classe, etc...
    $("form input.date").datepicker({
        dateFormat: 'dd/mm/yy', 
        firstDay:1,
        showButtonPanel: true
    }).attr("readonly","readonly");
    
    

});